<template>
  <div class="v-spinner">
    <svg viewBox="0 0 100 100">
      <circle
        :class="color"
        cx="50"
        cy="50"
        r="20" />
    </svg>
  </div>
</template>

<script>
export default {
  name: "VSpinner",
  props: {
    color: {
      type: String,
      default: "default",
      validator: function (val) {
        return ["default", "alt"].includes(val);
      },
    },
  },
};
</script>

<style src="./VSpinner.scss" lang="scss" />
