export default {
  state: {
    tmp: null,
  },
  getters: {
    getTmp: state => {
      return state.tmp;
    },
  },
  mutations: {
    setTmp: (state, payload) => {
      state.tmp = payload;
    },
  },
  actions: {
    setTmp: ({ commit }, payload) => {
      commit("setTmp", payload);
    },
  },
};
