import axios from "axios";
import api from "./api";

import { USER_ID } from "@/windowConfig";

export default {
  addPromoter(
    offerId,
    lang,
    email,
    signupReference,
    voucherCode,
    firstName,
    lastName,
  ) {
    return axios.post(`${api.url}/${lang}/pub/promoter/add`, {
      email,
      offerSId: offerId,
      signupReference,
      voucherCode,
      firstName,
      lastName,
    });
  },
  changeCurrency(currencyId) {
    return axios.post(`${api.url}/users/${USER_ID}/currency/${currencyId}`);
  },
  changeLanguage(languageId) {
    return axios.post(`${api.url}/users/${USER_ID}/language/${languageId}`);
  },
  confirmPromotersUpload(validationId, sendEmail) {
    return axios.post(`${api.url}/accounts/promoter/import`, {
      validationId,
      sendEmail,
    });
  },
  createProvider(payload) {
    return axios.post(`${api.url}/providers`, payload);
  },
  createAccount(payload) {
    return axios.post(`${api.url}/accounts`, payload);
  },
  deleteUser(accountId, userId) {
    return axios.delete(`${api.url}/accounts/${accountId}/users/${userId}`);
  },
  disableUser(accountId, userId) {
    return axios.post(
      `${api.url}/accounts/${accountId}/users/${userId}/disable`,
    );
  },
  //TODO make backend consistent for feature flags...
  enableSneakypeer(providerId) {
    return axios.post("/api/offers/sneakypeer/enable/" + providerId);
  },

  disableSneakypeer(providerId) {
    return axios.delete("/api/offers/sneakypeer/disable/" + providerId);
  },
  enableUser(accountId, userId) {
    return axios.post(
      `${api.url}/accounts/${accountId}/users/${userId}/enable`,
    );
  },
  disableAccountFeatureFlag(accountId, feature) {
    return axios.delete(
      `${api.url}/accounts/${accountId}/feature-flags/${feature}`,
    );
  },
  enableAccountFeatureFlag(accountId, feature) {
    return axios.put(
      `${api.url}/accounts/${accountId}/feature-flags/${feature}`,
    );
  },
  getAdvertisersForProvider(provider) {
    return axios.get(`${api.url}/accounts/advertiser-selector`, {
      params: { provider },
    });
  },
  getCurrentAccountData() {
    return axios.get(`${api.url}/account`);
  },
  getAccountDetails(accountId) {
    return axios.get(`${api.url}/accounts/${accountId}`);
  },
  getAccountFeatureFlag(accountId, flagName) {
    return axios.get(
      `${api.url}/accounts/${accountId}/feature-flags/${flagName}`,
    );
  },
  getAccounts(params, isPromoter = false) {
    return isPromoter
      ? axios.get(`${api.url}/accounts/listing/promoter`, {
          params,
        })
      : axios.get(`${api.url}/accounts`, {
          params,
        });
  },
  getBranding(accountId) {
    return axios.get(`${api.url}/account/${accountId}/branding`);
  },
  getCurrencies() {
    return axios.get(`${api.url}/exchange/currency`);
  },
  getCurrency(userId) {
    return axios.get(`${api.url}/users/${userId}/currency`);
  },
  getCSVSettings(accountId) {
    return axios.get(`${api.url}/accounts/${accountId}/csv-settings`);
  },
  getFeatureFlag(flagName) {
    return axios.get(`${api.url}/accounts/feature-flags/${flagName}`);
  },
  getOffersForAdvertiser(advertiserId) {
    return axios.get(`${api.url}/advertiser/${advertiserId}/offer`);
  },
  getAccountPricing(accountId) {
    return axios.get(`${api.url}/accounts/${accountId}/payment/pricing-type`);
  },
  getProviderPricing(providerId) {
    return axios.get(`${api.url}/providers/${providerId}/payment/pricing-type`);
  },
  getProviderData(providerId) {
    return axios.get(`${api.url}/providers/${providerId}/business`);
  },
  getProviders() {
    return axios.get(`${api.url}/providers`);
  },
  getSessionInfo() {
    return axios.get(`${api.url}/accounts/session-info`);
  },
  getUserAccount(accountId, userId) {
    return axios.get(`${api.url}/accounts/${accountId}/users/${userId}`);
  },
  getUsers(accountId) {
    return axios.get(`${api.url}/accounts/${accountId}/users`);
  },
  getManagersOrContacts(accountId) {
    return axios.get(`${api.url}/accounts/${accountId}/managers-or-contacts`);
  },
  deleteAccount(id) {
    return axios.delete(`${api.url}/accounts/delete/${id}`);
  },
  deletePublisherAccount(id) {
    return axios.delete(`${api.url}/account/remove-or-archive-publisher/${id}`);
  },
  resendUserInvitation(userId) {
    return axios.post(`/api/accounts/${userId}/invitation/resend`);
  },
  saveAccountData(accountId, payload) {
    return axios.put(`${api.url}/accounts/${accountId}`, payload);
  },
  saveAdInventory(payload) {
    return axios.post(`${api.url}/ad-inventories`, payload);
  },
  saveBranding(accountId, payload) {
    return axios.post(`${api.url}/account/${accountId}/branding`, payload);
  },
  saveCSVSettings(accountId, payload) {
    return axios.put(`${api.url}/accounts/${accountId}/csv-settings`, payload);
  },
  saveUser(accountId, payload) {
    return axios.post(`${api.url}/accounts/${accountId}/users`, payload);
  },
  saveAccountPricing(accountId, payload) {
    return axios.put(
      `${api.url}/accounts/${accountId}/payment/pricing-type`,
      payload,
    );
  },
  saveProviderPricing(providerId, payload) {
    return axios.put(
      `${api.url}/providers/${providerId}/payment/pricing-type`,
      payload,
    );
  },
  saveProviderData(providerId, payload) {
    return axios.post(`${api.url}/providers/${providerId}/business`, payload);
  },
  uploadPromoters(formData, offerId, lang, sendEmail) {
    return axios.post(
      `${api.url}/accounts/promoter/validate/import/${offerId}?lang=${lang}&sendEmail=${sendEmail}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
  },
};
