export default {
  state: {
    search: "",
  },
  getters: {
    getSearch: state => {
      return state.search;
    },
  },
  mutations: {
    setSearch: (state, payload) => {
      state.search = payload;
    },
  },
  actions: {
    setSearch: ({ commit }, payload) => {
      commit("setSearch", payload);
    },
  },
};
