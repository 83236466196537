<!-- eslint-disable vue/no-v-html -->
<template>
  <transition name="slide-in-bottom">
    <div
      v-show="isActive"
      role="alert">
      <div class="notification-container">
        <div
          ref="notification"
          :class="['notification', `notification--${type}`]">
          <div class="flex-row">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div
              class="notification-text"
              v-html="message" />
            <div
              class="notification-close ml-auto"
              @click="close">
              <Close />
            </div>
          </div>
          <div
            v-if="actions.length > 0"
            class="notification-actions">
            <VButton
              v-for="(action, idx) in actions"
              :key="idx"
              class="mt-1 capitalize"
              size="sm"
              state="secondary"
              :text="action.type"
              @click="onActionClick(action)" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
//utils
import { downloadFile } from "@/utils/downloadFile";
import { removeElement } from "@/utils/elementHelper.js";

//icons
import Close from "icons/Close";

//components
import VButton from "@/components/VButton/VButton";

export default {
  components: {
    Close,
    VButton,
  },
  props: {
    message: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "default",
      validator: value => {
        return ["success", "error", "info", "default"].indexOf(value) !== -1;
      },
    },
    duration: {
      type: Number,
      default: 5000,
    },
    actions: {
      type: Array,
      default: () => [],
    },
    credentials: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isActive: false,
      parentBottom: null,
    };
  },
  beforeMount() {
    this.setupContainer();
  },
  mounted() {
    this.showNotice();
    this.$nextTick(() => {
      const { height } = this.$refs.notification.getBoundingClientRect();
      this.$refs.notification.style.setProperty(
        "--notification-height",
        `${height}px`,
      );
    });
  },
  methods: {
    onActionClick(action) {
      switch (action.type) {
        case "download":
          downloadFile(action.content);
          break;
        default:
          console.warn(`Undefined action type: ${action.type}`);
      }
    },
    setupContainer() {
      this.parentBottom = document.querySelector(".notices");
      // No need to create them, they already exists
      if (this.parentBottom) return;

      if (!this.parentBottom) {
        this.parentBottom = document.createElement("div");
        this.parentBottom.className = "notices";
        if (this.credentials)
          this.parentBottom.classList.add("notices--credentials");
      }
      const container = document.body;
      container.appendChild(this.parentBottom);
    },
    showNotice() {
      this.isActive = true;
      this.parentBottom.insertAdjacentElement("afterbegin", this.$el);
      setTimeout(this.close, this.duration);
    },
    close() {
      this.isActive = false;

      setTimeout(() => {
        removeElement(this.$el);
      }, 400);
    },
  },
};
</script>

<style src="./VNotification.scss" lang="scss" />
