import Notification from "./VNotification";

import { createApp } from "vue";

const notification = {
  open(options) {
    let message;
    if (typeof options === "string") message = options;

    const defaultOptions = {
      message,
    };

    const propsData = Object.assign({}, defaultOptions, options);

    const mountElement = document.createElement("div");
    mountElement.classList.add("notices");

    //append to body if not exists
    if (!document.querySelector(".notices"))
      document.body.appendChild(mountElement);

    const notification = createApp(
      { extends: Notification },
      {
        ...propsData,
        onClose() {
          mountElement.parentNode.removeChild(mountElement);
          notification.unmount();
          mountElement.remove();
        },
      },
    );

    notification.mount(mountElement);
  },
};

export default notification;
