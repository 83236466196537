import App from "./App.vue";
import { createApp } from "vue";
import VueGtm from "@gtm-support/vue-gtm";
import { plugin as VueTippy } from "vue-tippy";

import Vue3Tour from "vue3-tour";
import interceptorSetup from "./interceptor";
import router from "./router";
import { store } from "./store";
import gettext from "./gettext-compile.config";
import notify from "@/components/VNotification";
import { VueReCaptcha } from "vue-recaptcha-v3";

import * as Sentry from "@sentry/vue";

import "@vueup/vue-quill/dist/vue-quill.snow.css";
import "normalize.css";
import "./styles/app.scss";

interceptorSetup();

const app = createApp(App);

//set global variables
app.config.globalProperties.$notification = notify;

//this is for Vue 3 setup
app.provide("$notification", notify);

const tippyConfig = {
  allowHTML: false,
  animateFill: false,
  animation: "fade",
  arrow: false,
  delay: [300, 0],
  duration: 100,
  interactive: false,
  maxWidth: null,
};

app.use(VueTippy, {
  onShow: options => {
    return !!options.props.content;
  },
  directive: "tippy",
  defaultProps: { ...tippyConfig },
});

app.use(VueGtm, {
  id: process.env.VUE_APP_GOOGLE_TAG_MANAGER_ID, // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
  defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
  compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
  nonce: "2726c7f26c", // Will add `nonce` to the script tag
  enabled: false, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
});

app.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_RECAPTCHA_SITEKEY,
  loaderOptions: {
    autoHideBadge: true,
  },
});

app.use(Vue3Tour);
app.use(gettext);
app.use(router);
app.use(store);

if (process.env.NODE_ENV !== "development") {

  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_ENV,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: process.env.VUE_APP_SENTRY_TRACING_RATE,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      process.env.VUE_APP_SENTRY_APP_URI ?? "localhost",
      /^\//,
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: process.env.VUE_APP_SENTRY_REPLY_RATE,
    replaysOnErrorSampleRate: process.env.VUE_APP_SENTRY_ERROR_RATE,
  });
}

app.mount("#app");
