export default {
  state: {
    activeRoute: "/",
  },
  getters: {
    getActiveRoute: state => state.activeRoute,
  },
  mutations: {
    setActiveRoute(state, index) {
      state.activeRoute = index;
    },
  },
  actions: {
    setActiveRoute({ commit }, index) {
      commit("setActiveRoute", index);
    },
  },
};
