export default {
  state: {
    email: "",
    hash: "",
    password: "",
  },
  getters: {
    getSignupEmail: state => {
      return state.email;
    },
    getSignupHash: state => {
      return state.hash;
    },
    getSignupPassword: state => {
      return state.password;
    },
  },
  mutations: {
    setSignupEmail: (state, payload) => {
      state.email = payload;
    },
    setSignupHash: (state, payload) => {
      state.hash = payload;
    },
    setSignupPassword: (state, payload) => {
      state.password = payload;
    },
  },
  actions: {
    setSignupEmail: ({ commit }, payload) => {
      commit("setSignupEmail", payload);
    },
    setSignupHash: ({ commit }, payload) => {
      commit("setSignupHash", payload);
    },
    setSignupPassword: ({ commit }, payload) => {
      commit("setSignupPassword", payload);
    },
  },
};
