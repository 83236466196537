<template>
  <button
    :type="type"
    :class="[
      'v-button',
      `v-button--${state}`,
      `v-button--${size}`,
      { 'v-button--loading': loading },
      { 'v-button--disabled': disabled || loading },
    ]"
    :style="{ width: `${width}rem` }"
    @click="click">
    <VSpinner
      v-if="loading"
      :color="state === 'default' ? 'default' : 'alt'" />
    <slot v-if="!loading">
      {{ text }}
    </slot>
    <template v-else>
      {{ loadingText }}
    </template>
  </button>
</template>

<script>
//constants
import { BUTTON_TYPE, BUTTON_STATE } from "@/constants";

//components
import VSpinner from "@/components/VSpinner/VSpinner";

export default {
  name: "VButton",
  components: { VSpinner },
  props: {
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    loadingText: { type: String, default: "Saving..." },
    type: {
      type: String,
      default: "button",
      validator: function (value) {
        return BUTTON_TYPE.indexOf(value) !== -1;
      },
    },
    size: {
      type: String,
      default: "md",
      validator: function (value) {
        return ["sm", "md", "lg", "xlg"].indexOf(value) !== -1;
      },
    },
    state: {
      type: String,
      default: "primary",
      validator: function (value) {
        return BUTTON_STATE.indexOf(value) !== -1;
      },
    },
    text: { type: String, default: null },
    width: { type: Number, default: null },
  },
  emits: ["click"],
  methods: {
    click() {
      if (this.disabled || this.loading) return;
      this.$emit("click");
    },
  },
};
</script>

<style src="./VButton.scss" lang="scss" />
