export default {
  state: {
    activeTabName: "",
    currentViewName: "",
    currentView: {
      name: "Untitled*",
      filters: {},
    },
    daterange: {},
    dateRelative: "",
    updateHelper: 0,
    sort: {},
  },
  getters: {
    getActiveTabName: state => {
      return state.activeTabName;
    },
    getCurrentView: state => {
      return state.currentView;
    },
    getDaterange: state => {
      return state.daterange;
    },
    getFilter: state => name => {
      return state.currentView.filters[name];
    },
    getFilterValues: state => {
      return state.currentView.filters;
    },
    getFiltersList: state => {
      return Object.keys(state.currentView?.filters);
    },
    getSort: state => {
      return state.sort;
    },
  },
  mutations: {
    deleteFilter: (state, filterName) => {
      state.updateHelper++;
      delete state.currentView.filters[filterName];
    },
    updateFilter: state => {
      state.updateHelper++;
    },
    setCurrentView: (state, payload) => {
      state.currentView = payload;
    },
    setCurrentViewName: (state, payload) => {
      state.currentViewName = payload;
    },
    setDaterange: (state, payload) => {
      state.daterange = payload;
    },
    setFilter: (state, payload) => {
      state.currentView.filters[payload.name] = payload.value;
    },
    setSort: (state, payload) => {
      state.sort = payload;
    },
    setViews: (state, payload) => {
      state[state.currentViewName] = payload;
    },
    setTabName: (state, payload) => {
      state.activeTabName = payload;
    },
  },
  actions: {
    deleteFilter: ({ commit }, payload) => {
      commit("deleteFilter", payload);
    },
    updateFilter: ({ commit }) => {
      commit("updateFilter");
    },
    setCurrentView: ({ commit }, payload) => {
      commit("setCurrentView", payload);
    },
    setCurrentViewName: ({ commit }, payload) => {
      commit("setCurrentViewName", payload);
    },
    setDaterange: ({ commit }, payload) => {
      commit("setDaterange", payload);
    },
    setFilter: ({ commit }, payload) => {
      commit("setFilter", payload);
    },
    setSort: ({ commit }, payload) => {
      commit("setSort", payload);
    },
    setViews: ({ commit }, payload) => {
      commit("setViews", payload);
    },
    setTabName: ({ commit }, payload) => {
      commit("setTabName", payload);
    },
  },
};
