export async function downloadFile(filePath, fileName = null) {
  try {
    const response = await fetch(filePath);
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    if (fileName) a.download = fileName;
    a.click();

    URL.revokeObjectURL(url);
  } catch (err) {
    console.warn(err);
  }
}
