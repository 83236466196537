import axios from "axios";
import { ACCOUNT_IS_ACTIVE, ABORT_CONTROLLER } from "@/windowConfig";

const IGNORE_URL = ["/api/account", "/api/accounts/session-info"];
export default () => {
  axios.interceptors.response.use(
    response => {
      if (
        response.request?.responseURL?.includes("/signin") &&
        ACCOUNT_IS_ACTIVE
      ) {
        window.location = "/signin";
      }
      return response;
    },
    err => {
      //check if request was cancelled
      if (axios.isCancel(err)) {
        
        // stop execution if request was cancelled
        return new Promise(() => {});
      }

      return Promise.reject(err);
    },
  );

  axios.interceptors.request.use(
    config => {
      return IGNORE_URL.includes(config.url)
        ? config
        : { ...config, signal: ABORT_CONTROLLER.signal };
    },
    err => {
      return Promise.reject(err);
    },
  );
};
