import { createGettext } from "vue3-gettext";
import { LANGUAGES } from "@/constants";
import translations from "@/language/translations";

const AVAILABLE_LANGUAGES = LANGUAGES.reduce((acc, { id, name }) => {
  acc[id] = name;
  return acc;
}, {});

const gettext = createGettext({
  availableLanguages: AVAILABLE_LANGUAGES,
  defaultLanguage: "en",
  silent: true,
  translations,
});

export default gettext;
